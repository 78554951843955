import set from 'lodash.set';
import { pathOr, has } from 'ramda';
import { scaleThreshold } from 'd3-scale';
import hash from 'object-hash';
import axios from 'axios';

// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';
// constants
import { DEFAULT_BASEMAP_ICON, MAPBOX_MAPS } from '@/utils/basemap-config';
import {
  LAYER_SOURCE,
  TEMPLATE_TL_URI_TYPE,
  MOUSE_EVENTS,
  TEMPLATE_LAYERS,
  TEMPLATE_LAYER_TYPE,
  MAP_LAYER_TYPE,
  ELLIPSIS_LAYER_INFO_URL,
  MAX_ELLIPSIS_ZOOM_LEVEL,
} from '@/utils/constants';

/**
 * Sets the basemap based on the id of the style to the genearl mapconfig
 * @param {string} idOfCurrentBasemapStyle  -  id of basemap style
 * @param {object} mapConfig  - the global map config generated from fetchMap
 * @returns {void}
 */
export const setBaseMapToConfig = (idOfCurrentBasemapStyle, mapConfig) => {
  if (idOfCurrentBasemapStyle && mapConfig) {
    let reconfiguredMapConfig = set(
      mapConfig,
      'data.keplerMapConfig.config.mapStyle.styleType',
      idOfCurrentBasemapStyle,
    );
    reconfiguredMapConfig = set(
      mapConfig,
      'map.mapStyle.styleType',
      idOfCurrentBasemapStyle,
    );
    return reconfiguredMapConfig;
  }
};

/**
 * Method used to remove the maplibre logo
 * the prop `maplibreLogo` doesn't work on Map instance from react-map-gl
 * @returns {void}
 */
export const removeMapLogo = () => {
  const logoClass = '.mapboxgl-ctrl-logo';
  const el = document.querySelector(logoClass);
  if (el) {
    try {
      el.remove();
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * Remove the google prefix
 *
 * @param {string} currentBasemapStyleId  - style map name
 * @returns {string} - the style name without the google prefix
 */
export const mapGoogleToDefault = (currentBasemapStyleId) => {
  const GOOGLE_PREFIX = /google-/;
  if (GOOGLE_PREFIX.test(currentBasemapStyleId)) {
    return (currentBasemapStyleId = currentBasemapStyleId.replace(
      GOOGLE_PREFIX,
      '',
    ));
  }
  return currentBasemapStyleId;
};

export const normalizeLayerConfigs = (layerConfigs) => {
  layerConfigs.forEach((layerConfig) => {
    layerConfig.id = layerConfig.id || guid();
    layerConfig.pathId = findPathId(layerConfig);
  });
};

/**
 * Sets the state with custom legend layers, and adding the visibility of them
 *
 * @param {array} extraLayers - the layer coming from the config bucket
 * @param {array} layersFromProjectViewConfig - array layers from the state
 * @param {Function} dispatch  - state dispatch function
 * @param {Function} setCustomLegendLayers  - state method for setting the custom legend layers
 * @returns {void}
 */
export const setStateCustomLegendLayers = (
  extraLayers = [],
  layersFromProjectViewConfig = [],
  dispatch = () => {},
  setCustomLegendLayers = () => {},
) => {
  // set the customLayers
  const modifiedCustomLegendLayers = isArrayNotEmpty(extraLayers)
    ? extraLayers.map((layer) => {
        return {
          ...layer,
          visible:
            isLayerVisible(
              layersFromProjectViewConfig.find(
                (layerFromProjectViewConfig) =>
                  layerFromProjectViewConfig?.id === layer.id,
              )
            ) || isLayerVisible(layer),
          id: layer.id,
        };
      })
    : extraLayers;

  if (isArrayNotEmpty(modifiedCustomLegendLayers)) {
    dispatch(setCustomLegendLayers(modifiedCustomLegendLayers));
  }
};

/**
 *
 * @param {array} extraBaseMaps - basemaps from bucket config
 * @param {Function} dispatch  - state dispatch function
 * @param {Function} setCustomBasemapStyles  - state method for setting the custom basemaps
 */
export const setStateCustomBasemap = (
  extraBaseMaps = [],
  dispatch = () => {},
  setCustomBasemapStyles = () => {},
) => {
  if (isArrayNotEmpty(extraBaseMaps)) {
    dispatch(setCustomBasemapStyles(extraBaseMaps));
  }
};

/**
 * Creates a slug string as an id
 *
 * @param {string} str  - string to be converted to a slug string
 * @returns {string} - the modified string to string with slug
 */
export const toSlug = (str = '') => {
  if (!isNilOrEmpty(str)) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeiiiioooouuuunc------';

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }
  return str;
};

const DEFAULT_BASEMAP_STYLE_PROP = ['name', 'uri'];
/**
 * Reformats the basemap array to be used for the Basemap component
 *
 * @param {array} customBasemapStyles - custom basemap styles
 * @returns {array}  - a new formatted basemap to be used for Basemap component
 */
export const createBaseMapsfromConfig = (customBasemapStyles = []) => {
  return customBasemapStyles
    .filter((customBasemapStyle) => {
      const hasAllProps = DEFAULT_BASEMAP_STYLE_PROP.every(
        (defaultBasemapStyle) =>
          Object.keys(customBasemapStyle).indexOf(defaultBasemapStyle) !== -1,
      );
      return hasAllProps ? customBasemapStyle : undefined;
    })
    .map((filteredCustomBasemapStyle) => ({
      id: toSlug(filteredCustomBasemapStyle?.name),
      label: filteredCustomBasemapStyle?.name,
      icon: filteredCustomBasemapStyle?.icon
        ? filteredCustomBasemapStyle?.icon
        : DEFAULT_BASEMAP_ICON,
      options: {
        mapType: MAPBOX_MAPS.MAPBOX,
      },
      url: filteredCustomBasemapStyle?.uri,
      type: MAPBOX_MAPS.MAPBOX,
    }));
};

/**
 * Checks if a layer has geojson data
 *
 * @param {object} rawLayer  - layer object
 * @param {object} GEOJSON_LAYER_MAPPING  - geojson layer mapping
 * @returns {string|boolean}
 */
export const hasGeoJsonData = (rawLayer, GEOJSON_LAYER_MAPPING) => {
  const type = pathOr('', ['type'], rawLayer);
  if (has(type, GEOJSON_LAYER_MAPPING)) {
    return GEOJSON_LAYER_MAPPING[type];
  }
  return false;
};

export const orderLayerIds = (props) => {
  const cartoLayers = pathOr([], ['cartoLayers'], props);
  const customLegendLayers = pathOr([], ['customLegendLayers'], props);
  let defaultLayersOrder = pathOr([], ['defaultLayersOrder'], props);

  if (!isArrayNotEmpty(defaultLayersOrder)) {
    return cartoLayers.concat(customLegendLayers).map((layer) => layer.id);
  }

  let added = [];
  let _cartoLayers = Array.from(cartoLayers);

  // build ordered layers
  let ordered = defaultLayersOrder.map((id_) => {
    let layer;
    if (id_ === null) {
      layer = _cartoLayers.shift();
    } else {
      layer = customLegendLayers.find((lyr) => lyr.id === id_);
    }
    added.push(layer);
    return layer;
  });

  ordered = ordered.filter((lyr) => lyr !== undefined);

  // push layers not ordered
  for (const layer of _cartoLayers.concat(customLegendLayers)) {
    if (!added.includes(layer)) ordered.push(layer);
  }

  return ordered.map((layer) => layer.id);
};

/**
 * Method to generate a guid
 *
 * @returns {string} - create a unique guid id
 */
export const guid = () => self.crypto.randomUUID();

/**
 * Method for creating a Layer instance
 *
 * @param {object} layerOptions  - layer to be appended on layers
 * @returns {LayerComponent} - DeckGL Layer instance
 */
export const createLayerComponent = (layerConfig, layerComponent) => {
  let uri = pathOr('', ['uri'], layerConfig);
  const id = pathOr('', ['id'], layerConfig);
  const layersInfo = pathOr({}, ['layersInfo'], layerConfig);
  delete layersInfo?.layerId;
  const layerVisible = isLayerVisible(layerConfig);
  if (layerVisible && !isNilOrEmpty(uri)) {
    // add a fail safe not to trigger if we detect {timestampId}
    if (!/{timestampId}/.test(uri)) {
      return layerComponent(uri, {
        visible: layerVisible,
        id: id,
        layersInfo,
      });
    }
  }

  return null;
};

/**
 * Method for appending a layer to the generic layers
 *
 * @param {object} layerOptions  - layer to be appended on layers
 * @returns {void}
 */
export const appendNewLayer = (layerOptions) => {
  const layerConfig = pathOr(null, ['layerConfig'], layerOptions);
  const layers = pathOr([], ['layers'], layerOptions);
  const layerComponent = pathOr(null, ['layerComponent'], layerOptions);

  if (!isNilOrEmpty(layerConfig) && !isNilOrEmpty(layerComponent)) {
    const layerInstance = createLayerComponent(layerConfig, layerComponent);
    if (layerInstance !== null) layers.push(layerInstance);
  }
};
/**
 * Returns the array prop name based on a array of prop names
 *
 * @param {object} object - the object to search in
 * @param {array} props  - array of prop names
 * @returns {string|null} - prop name
 */
export const getObjectPropNameByProps = (object, props) => {
  for (const prop of props) {
    if (has(prop, object)) {
      return prop;
    }
  }
  return null;
};


export const extractPathIdFromUri = (uri) => {
  let parts;
  try {
    parts = (new URL(uri)).pathname.split('/');
  }
  catch (e) {
    return;
  }

  let index;
  if (uri.includes('/path/')) {
    index = parts.indexOf('path');
  }
  else if (uri.includes('/ogc/mvt/')) {
    index = parts.indexOf('mvt');
  }
  if (index) {
    const pathId =  parts[index + 1];
    if (pathId != '{pathId}') return pathId;
  }

  return;
}

export const findPathId = (layerConfig) => {
  let pathId = layerConfig.pathId;
  if (!pathId) {
    for (const uri of [
      layerConfig?.uri,
      layerConfig?.uriTemplate,
      layerConfig?.styleUri,
      layerConfig?.styleUriTemplate,
    ]) {
      if (uri) {
        pathId = extractPathIdFromUri(uri);
        if (pathId) break;
      }
    }
  }

  return pathId;
}

export const createLayerMetadataUri = (layerConfig) => {
  let pathId = findPathId(layerConfig);
  if (!pathId) throw Error('Cannot find pathId for Layer Metadata URI');
  return `${ELLIPSIS_LAYER_INFO_URL}${pathId}`;
};

const replacePlaceholders = (uriTemplate, placeholders, valuesMap) => {
  let uri = uriTemplate;
  for (const placeholder of placeholders) {
    let value;
    switch (placeholder) {
      case 'style':
      case 'styleId':
        value = (
          pathOr(undefined, ['style'], valuesMap) ||
          pathOr(undefined, ['styleId'], valuesMap)
        );
        break;
      default:
        value = pathOr(undefined, [placeholder], valuesMap);
    }
    if (value !== undefined) {
      uri = uri.replace('{' + placeholder + '}', value);
    }
  }
  return uri;
}

export const createLayerDataUri = (layerConfig, templateKeyword='uriTemplate') => {
  let uriTemplate = pathOr('', [templateKeyword], layerConfig);
  const pathId = pathOr('', ['pathId'], layerConfig);
  const style = pathOr('', ['style'], layerConfig);
  const token = pathOr('', ['token'], layerConfig);
  const layerSource = pathOr('', ['layerSource'], layerConfig);
  const layerType = pathOr('', ['layerType'], layerConfig);
  let placeholders = ['pathId', 'style', 'token'];
  const valuesMap = {
    pathId: pathId,
    style: style,
    token: token,
  };

  switch (layerSource) {
    case LAYER_SOURCE.ELLIPSIS_DRIVE:
      placeholders = pathOr([], ['uriTemplatePlaceholders'], layerConfig);
      valuesMap.styleId = pathOr('', ['styleId'], layerConfig);
    default: 
      return replacePlaceholders(uriTemplate, placeholders, valuesMap);
  }
}

export const createLayerStyleUri = (layerConfig) => {
  return createLayerDataUri(layerConfig, 'styleUriTemplate');
}

export const isLayerVisible = (layerConfig) => {
  return layerConfig?.visible === true;
};

export const assembleRequestHeaders = (layerConfig) => {
  const token = layerConfig.token;
  if (!token) return {};

  return {
    Authorization: `Bearer ${token}`,
  };
};

/**
 * Use a GET request to the url
 *
 * @param {string} url - url string
 * @returns {object} - the url response
 */
export const fetchDataFromUrl = async (url='', headers={}) => {
  if (isNilOrEmpty(url)) return;

  const request = {
    url: url,
    method: 'GET',
  };

  // given existing env configs may have tokens with insufficient privileges
  // and our own misunderstanding of tokens included as part of
  // a XYZ raster or MVT layer are scoped to only read the data, it is
  // expected that the request may fail.
  // if the response is a 401, retry without headers

  let response;
  try {
    response = await axios(request);
  }
  catch (e) {
    request.headers = headers;
    response = await axios(request);
  }

  return response.data;
};

export const isTimelineLayer = (layerConfig) => {
  const layerSource = pathOr('', ['layerSource'], layerConfig);
  switch (layerSource) {
    default: 
      return TEMPLATE_LAYERS.includes(layerConfig?.layerType)
    case LAYER_SOURCE.ELLIPSIS_DRIVE:
      const placeholders = pathOr('', ['uriTemplatePlaceholders'], layerConfig);
      return placeholders.includes('timestampId');
  }
};

export const getLayerTypeMetadata = (response) => {
  return pathOr({}, [response.type], response)
}

export const extractTimestamps = (response) => {
  return pathOr(
    [],
    ['timestamps'],
    getLayerTypeMetadata(response),
  );
}

export const mapTimestamps = (timestamps, props) => {
  const mappedTimestamps = timestamps
    .filter((timestamp) => timestamp?.status === 'active')
    .map((timestamp) => {
      const to = pathOr('', ['date', 'to'], timestamp);
      const from = pathOr('', ['date', 'from'], timestamp);
      const timeStampId = pathOr('', ['id'], timestamp);
      let timeStampDates = (
        from === to ?
          `${new Date(from).toLocaleString()}` :
          `${new Date(from).toLocaleString()} - ${new Date(to).toLocaleString()}`
      );
      return {
        timeStampId,
        timeStampDates,
        ...props,
      };
    });

  return mappedTimestamps;
}

/**
 * Extracts the timestamps from a array of layers
 *
 * @param {array} layers  - array of custom layers
 * @returns {array}  - an array of timestamps
 */
export const fetchLayersTimestampsData = async (layers) => {
  const modifiedTimestamps = [];
  if (isArrayNotEmpty(layers)) {
    for (const layer of layers) {
      const layerMetadataUri = createLayerMetadataUri(layer);
      const customLayerId = pathOr(null, ['id'], layer);
      const layerType = pathOr(null, ['layerType'], layer);
      const customLayerUri = createLayerDataUri(layer);

      await fetchDataFromUrl(
        layerMetadataUri,
        assembleRequestHeaders(layer),
      ).then((response) => {
        if (!isNilOrEmpty(response)) {
          const timestamps = extractTimestamps(response);

          if (isArrayNotEmpty(timestamps)) {
            const mappedTimestamps = mapTimestamps(
              timestamps,
              {
                customLayerId,
                customLayerUri,
                layerType,
              }
            );

            if (isArrayNotEmpty(mappedTimestamps)) {
              modifiedTimestamps.push(...mappedTimestamps);
            }
          }
        }
      });
    }
  }
  return modifiedTimestamps;
};

/**
 * Trims a html string based on classes and a number of occurences
 *
 * @param {string} htmlData  - html data
 * @param {number} stripFromIndex  - filter the data based on a number
 * @param {array} classes  - array of classes to look for
 * @param {string} parentClass  - parent class name to wrap in
 * @returns {string} - stripped string
 */
export const stripHTMLTooltip = (
  htmlData = '',
  stripFromIndex = 0,
  classes = [],
  parentClass = '',
) => {
  let regexpStr = '';
  if (
    isArrayNotEmpty(classes) &&
    !isNilOrEmpty(htmlData) &&
    !isNilOrEmpty(stripFromIndex)
  ) {
    for (const cls of classes) {
      regexpStr += `<div class="${cls}">((?:(?:(?!<div[^>]*>|</div>).)+|<div[^>]*>.*?</div>)*)</div>`;
    }
    if (!isNilOrEmpty(regexpStr)) {
      const regexp = new RegExp(regexpStr, 'g');
      const allOccurrences = [...htmlData.matchAll(regexp)];

      if (isArrayNotEmpty(allOccurrences)) {
        const occurrencesToStr = allOccurrences
          .map((occurrence) => occurrence[0])
          .filter((_, index) => index < stripFromIndex)
          .join('');
        var strRegExPattern = ' class="' + parentClass + '"';
        const newHtml = htmlData
          .replaceAll(regexp, '')
          .replace(new RegExp(strRegExPattern, 'g'), '');
        return `<div class="${parentClass}">${newHtml}${occurrencesToStr}</div>`;
      }
    }
  }
  return htmlData;
};

/**
 * Toggles the drawer(info panel) on/off
 *
 * @param {object} DRAWER_ARGS - a set of args passed to an object
 * @returns {void}
 */
export const toggleDrawer = ({
  anchorType,
  open,
  setPopupConfig,
  visibilityDrawer,
  setVisibilityDrawer,
  debounceMarkerInVieport,
  viewportObject,
  popupConfigReference,
}) => {
  const valueOfPopupConfig = pathOr(null, ['current'], popupConfigReference);
  const popupConfigTrigger = pathOr(null, ['trigger'], valueOfPopupConfig);
  if (!open) {
    setPopupConfig(false);
  } else {
    if (!isNilOrEmpty(viewportObject)) {
      if (popupConfigTrigger === MOUSE_EVENTS.CLICK) {
        debounceMarkerInVieport(viewportObject);
      }
    }
  }
  setVisibilityDrawer({ ...visibilityDrawer, [anchorType]: open });
};

/**
 * Check if marker is in viewport
 * @param {object} viewportObject - from deckgl containing viewport, mouse xy, etc
 * @returns {void}
 */
export const checkIfMarkerIsInViewport = ({
  viewportObject = null,
  dispatch,
  setViewState,
  viewState,
  DRAWER_MAX_MIN_WIDTH,
}) => {
  if (!isNilOrEmpty(viewportObject)) {
    const WIDTH_ADJUSTMENT = 50; // small adjustement for x mouse pos
    const x = pathOr(null, ['x'], viewportObject);
    const width = pathOr(null, ['viewport', 'width'], viewportObject);

    if (!isNilOrEmpty(width) && !isNilOrEmpty(x)) {
      const isInViewport =
        x + WIDTH_ADJUSTMENT > width - DRAWER_MAX_MIN_WIDTH ? false : true;
      if (!isInViewport && !isNilOrEmpty(viewState)) {
        const coordinate = pathOr([], ['coordinate'], viewportObject);
        if (isArrayNotEmpty(coordinate)) {
          dispatch(
            setViewState({
              ...viewState,
              latitude: coordinate[1],
              longitude: coordinate[0],
            }),
          );
        }
      }
    }
  }
};

/**
 * Sets the info for the custom layer to be used for layers(e.g zoom prop)
 *
 * @param {object} layerConfigs - layer configs
 * @param {function} dispatch - redux dispatch
 * @param {function} setCustomLayersInfo - redux method
 * @returns {void}
 */
export const setStateLayerInfo = async (
  layerConfigs = [],
  dispatch,
  setCustomLayersInfo,
) => {
  if (!isArrayNotEmpty(layerConfigs)) return;

  const pathPromises = layerConfigs.map((layerConfig) => {
    return fetchDataFromUrl(
      createLayerMetadataUri(layerConfig),
      assembleRequestHeaders(layerConfig),
    ).then((response) => {
      return {
        layerConfig,
        response: response,
      }
    });
  });
  if (!isArrayNotEmpty(pathPromises)) return;

  const results = await Promise.all(pathPromises);
  if (!isArrayNotEmpty(results)) return;

  for (const result of results) {
    const layerConfig = result.layerConfig;
    const response = result.response;
    if (!layerConfig || !response) return;

    const layerId = layerConfig.id;
    const pathId = pathOr(null, ['id'], response);
    if (isNilOrEmpty(pathId) || pathId != layerConfig.pathId) return;

    const layerSource = layerConfig?.layerSource;
    const layerType = pathOr(null, ['type'], response);

    if (layerSource === LAYER_SOURCE.ELLIPSIS_DRIVE) {
      await resolveEllipsisStyle(layerConfig, response);
    }

    if (layerType !== 'vector') return;

    // every valid layer has at least one timestamp
    const timestamps = extractTimestamps(response);
    const maxZoom = timestamps.reduce(
      (min_zoom, timestamp) => {
        return Math.min(
          pathOr(MAX_ELLIPSIS_ZOOM_LEVEL, ['zoom'], timestamp),
          pathOr(MAX_ELLIPSIS_ZOOM_LEVEL, ['precompute', 'vectorTileZoom'], timestamp),
          min_zoom,
        )
      },
      MAX_ELLIPSIS_ZOOM_LEVEL
    );

    const mostCommonGeometryType = findMostCommonGeometryTypeFromTimestamps(
      timestamps
    );

    let styleUri;
    if (
      [
        MAP_LAYER_TYPE.TEMPLATE_MVT_LAYER,
        MAP_LAYER_TYPE.MVT_LAYER,
        MAP_LAYER_TYPE.MVT,
      ].includes(layerConfig.layerType)
    ) {
      if (layerConfig?.styleUri) {
        styleUri = layerConfig.styleUri;
      }
      else if (layerConfig?.styleUriTemplate) {
        styleUri = createLayerStyleUri(layerConfig);
        if (isArrayNotEmpty(timestamps)) {
          styleUri = styleUri.replace('{timestampId}', timestamps[0].id);
        }
      }
    }

    if (!styleUri) {
      dispatch(
        setCustomLayersInfo({
          maxZoom: maxZoom,
          geometryType: mostCommonGeometryType,
          layerId,
        }),
      );
      return;
    }

    const styleResponse = await fetchDataFromUrl(
      styleUri,
      assembleRequestHeaders(layerConfig),
    );
    dispatch(
      setCustomLayersInfo({
        maxZoom: maxZoom,
        geometryType: mostCommonGeometryType,
        layerInfo: styleResponse.layers,
        layerId,
      }),
    );
  }
};

export const resolveEllipsisStyle = async (layerConfig, layerMetadata) => {
  const styleId = pathOr(null, ['styleId'], layerConfig);
  if (styleId) return styleId;

  const pathType = layerMetadata.type;
  const styleConfig = pathOr(null, ['styleConfig'], layerConfig);
  const knownStyles = pathOr(
    [],
    ['styles'],
    getLayerTypeMetadata(layerMetadata)
  );

  const searchForStyle = (knownStyles, testFn) => {
    for (const style of knownStyles) {
      if (testFn(style) === true) return style;
    }
    return false;
  };

  const defaultStyle = searchForStyle(
    knownStyles,
    (style) => style.default === true
  );
  if (!styleConfig) {
    if (defaultStyle !== false) {
      layerConfig.styleId = defaultStyle.id;
      return layerConfig.styleId;
    }
  }

  const styleName = hash.sha1(styleConfig);
  const style = searchForStyle(
    knownStyles,
    (style) => style.name === styleName
  );
  if (style !== false) {
    layerConfig.styleId = style.id;
    return layerConfig.styleId;
  }

  const token = layerConfig.token;
  if (!token) throw Error('Cannot create style without Token');
  const headers = assembleRequestHeaders(layerConfig);
  headers['Content-Type'] = 'application/json';

  const url = new URL(createLayerMetadataUri(layerConfig));
  url.pathname += `/${pathType}/style`;

  const request = {
    url: url.toString(),
    method: 'POST',
    data: {
      ...styleConfig,
      name: styleName,
      default: false,
    },
    headers: headers,
  }
  try {
    const response = await axios(request);
    layerConfig.styleId = response.data.id;
    return layerConfig.styleId;
  }
  catch (e) {
    console.error(`Error creating Style for Layer. Using default style: ${layerConfig.id}`);
    console.error(e);
    if (defaultStyle !== false) {
      layerConfig.styleId = defaultStyle.id;
      return layerConfig.styleId;
    }
    else {
      console.error(`Default style not found. This Layer will probably not work: ${layerConfig.id}`);
      return;
    }
  }
}

export const findMostCommonGeometryTypeFromTimestamps = (timestamps) => {
  const totalGeometryFractions = timestamps.map(
    (timestamp) => {
      const geometryTypes = pathOr([], ['statistics', 'geometryTypes'], timestamp);
      const maxGeometryType = geometryTypes.reduce(
        (maxGeometryType, geometryType) => {
          if (maxGeometryType === undefined) return geometryType;
          if (geometryType.fraction > maxGeometryType.fraction) return geometryType;
          return maxGeometryType;
        },
        undefined
      );
      return maxGeometryType;
    }
  ).reduce(
    (totalFraction, maxGeometryType) => {
      if (maxGeometryType === undefined) return totalFraction;
      const fraction = (
        (maxGeometryType.type in totalFraction) ?
          totalFraction[maxGeometryType.type] :
          0
      );
      totalFraction[maxGeometryType.type] = fraction + maxGeometryType.fraction;
      return totalFraction;
    },
    {}
  );

  let mostCommonGeometryFraction;
  for (const [geometryType, totalFraction] of Object.entries(totalGeometryFractions)) {
    if (
        (mostCommonGeometryFraction === undefined) ||
        (totalFraction > mostCommonGeometryFraction[1])
    ) {
      mostCommonGeometryFraction = [geometryType, totalFraction];
    }
  }
  const mostCommonGeometryType = (
    mostCommonGeometryFraction.length > 0 ?
      mostCommonGeometryFraction[0].toLowerCase().trim() :
      undefined
  );

  return mostCommonGeometryType;
};

const hexToRGBArray = (hex) =>
  hex.match(/[A-Za-z0-9]{2}/g).map((v) => parseInt(v, 16));
const asRGBA = (mvtColor, opacityFloat) => {
  const rgba = hexToRGBArray(mvtColor);
  rgba.push(parseInt(opacityFloat * 255));
  return rgba;
};

const buildColorGetter = (color, opacity) => {
  /*
  !!! DO NOT MODIFY THE COLOR OR OPACITY VALUES !!!
  */

  if (typeof color === 'string') {
    return asRGBA(color, opacity);
  }
  else if (!(color instanceof Array)) {
    console.warn(`Unknown Color structure: ${color}`);
    return;
  }

  const statement = color[0];
  switch (statement) {
    case 'case':
      const condition = color[1];
      const evalClause = color[2];
      const fallbackColor = color[3];
      const fallbackRGBA = asRGBA(fallbackColor, opacity);
      const evalEstimator = evalClause[0];
      const evalMethod = evalClause[1];
      const evalVariable = evalClause[2];
      const evalDomain = [];
      const evalRange = [];
      for (let idx = 3; idx < evalClause.length; idx += 2) {
        const domainValue = evalClause[idx];
        const rangeValue = evalClause[idx + 1];

        if (rangeValue === undefined) {
          console.warn(`Imbalanced evaluation clause: ${evalClause}`);
          break;
        }

        evalDomain.push(domainValue);
        evalRange.push(rangeValue);
      }

      let colorFn;
      if (evalEstimator === 'interpolate' && evalMethod[0] === 'linear') {
        colorFn = scaleThreshold(evalDomain, evalRange);
      }
      else {
        return fallbackRGBA;
      }

      return (data) => {
        if (evalVariable[0] !== 'get') {
          console.warn(`Unknown Variable operation: ${evalVariable} ${fillColor}`);
          return fallbackRGBA;
        }
        const value = data.properties[evalVariable[1]]
        if (value !== undefined) {
          return asRGBA(colorFn(value), opacity);
        }
      };
    default:
      console.warn(`Unknown Color statement: ${statement} ${color}`);
      return;
  }
};

export const getCustomLayerInfo = (layerId, customLayersInfo) => {
  const layerInfo = customLayersInfo.find(
    (layer) => layer?.layerId === layerId,
  );
  if (isNilOrEmpty(layerInfo) || isNilOrEmpty(layerInfo.layerInfo)) {
    return;
  }

  const geometryType = pathOr(null, ['geometryType'], layerInfo);
  const isPolygon = geometryType.search('polygon') !== -1;

  let styleProps = {
    maxZoom: pathOr(MAX_ELLIPSIS_ZOOM_LEVEL, ['maxZoom'], layerInfo),
    elevationScale: 5,
    extruded: false,
    filled: false,
    getFillColor: null,
    getLineColor: null,
    getLineWidth: 1,
    getPointRadius: 1,
    highlightColor: null,
    stroked: false,
    visible: false,
    wireframe: false,
  };

  layerInfo.layerInfo.forEach((style) => {
    const paint = style.paint;
    styleProps.visible = true;

    switch (style.id) {
      case 'fill':
      case 'fillStyle':
        if (isPolygon) {
          styleProps.filled = true;
          styleProps.getFillColor = buildColorGetter(
            paint['fill-color'],
            paint['fill-opacity'],
          )
        }
        break;
      case 'line':
      case 'lineStyle':
        if (!isPolygon) {
          styleProps.stroked = true;
          styleProps.getLineWidth = paint['line-width'];
          styleProps.getLineColor = buildColorGetter(
            paint['line-color'],
            paint['line-opacity'],
          );
        }
        break;
      case 'point':
      case 'pointStyle':
        styleProps.getPointRadius = paint['circle-radius'];
        break;
      case 'border':
      case 'borderStyle':
        if (isPolygon) {
          styleProps.stroked = true;
          styleProps.getLineWidth = style['line-width'] || paint['line-width'];
          styleProps.getLineColor = buildColorGetter(
            paint['line-color'],
            paint['line-opacity'],
          );
        }
        break;
      default:
        console.warn(`Unknown Style ID: ${style.id}`);
    }
  });

  return styleProps;
};
